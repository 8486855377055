import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/VoiceTextField';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import { useMultiPurposeFileUpload } from '@ifca-root/react-component/src/utils/hooks/useMultiPurposeFileUpload';
import {
	Checkbox,
	FormControl,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox, ThumbUp } from '@material-ui/icons';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { NoImage } from 'assets';
import BigNumber from 'bignumber.js';
import { MainHeaderCustomer } from 'components/Layout/MainHeaderCustomer';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'containers/helper/SystemMsg';
import {
	GetOrderDocument,
	GetOrderSummaryDocument,
	GetScheduledDailyMenuCategoryDocument,
	OrderItemStatus,
	OrderStatus,
	TableStatus,
	useCreateOrderItemWithOptionsMutation,
	useCreateOrderItemWithSetMenuOptionsMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletQuery,
	useGetOutletVisitorPatronQuery,
	useGetScheduledDailyMenuCategoryLazyQuery,
	useGetUomQuery,
} from 'generated/graphql';
import { formatTimeto12h } from 'helpers/formatDate';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { PatronFooter } from '../../components/Footer/PatronFooter';
import './order.scss';

interface OptionItem {
	remark: string;
	name: string;
	openItem: string;
	openPrice: number;
	specialRequest: String;
	option: any[];
}

export const Order = () => {
	useEffect(() => {
		console.log('Order Customer App');
	}, []);
	const history = useHistory();

	const { outletID, tableID, qrID, menuID, orderID } = useParams<
		Record<string, any>
	>();
	const [openMenu, setOpenMenu] = useState(false);
	const [openSetMenuDialog, setOpenSetMenuDialog] = useState(false);

	const editData = useState();
	//const editData: any = location?.state;
	const [text, setText] = useState();
	const [record, setRecord] = useState(false);
	const [audioData, setAudioData] = useState(null);
	const [audioInput, setAudioInput] = useState('');
	const { files, handleSingleRemark } = useMultiPurposeFileUpload();

	const [currMenu, setCurrMenu] = useState<any>();
	const [quantity, setQuantity] = useState(1);
	const [takeAway, setTakeAway] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [selfOrder, setSelfOrder] = useState(true);
	const [selfPayment, setSelfPayment] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	useEffect(() => {
		if (currMenu?.specialRequest) {
			setValue('specialRequest', currMenu?.specialRequest);
		}
	}, [currMenu]);

	const yupSchema = yup.object().shape({
		openItem: currMenu?.isOpenItem
			? yup
					.string()
					.required('Open item name is required')
					.trim()
			: null,
		openPrice: currMenu?.isOpenItem
			? yup.number().required('Open item price is required')
			: null,
	});

	const {
		control,
		register,
		unregister,
		handleSubmit,
		setValue,
		watch,
		reset,
		clearErrors,
		errors,
	} = useForm<OptionItem>({
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const categoryDDClose = () => {
		setAnchorEl(null);
	};

	const menuSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const handleMenuCategoryClick = (
		// event: React.MouseEvent<HTMLElement>,
		event: any,
		index: number,
	) => {
		setSelectedIndex(index);
		setAnchorEl(null);
		localStorage.setItem(
			'searchFilter',
			JSON.stringify({
				searchString: menuSearch?.searchString ?? '',
				category: event?.ID,
			}),
		);
		history.push(
			`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${event.ID}`,
		);
	};

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (quantity === currMenu?.menuItem?.dailyAvailability?.outstandingQty) {
				setQuantity(quantity);
			} else {
				setQuantity(quantity + 1);
			}
		} else {
			if (quantity === 1) {
				setQuantity(1);
			} else {
				setQuantity(quantity - 1);
			}
		}
	};
	//GET ORDER
	//GET ORDER

	const {
		data: { getOrder } = { getOrder: [] },
		called: getOrderCalled,
		loading: getOrderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: { ID: orderID, status: OrderStatus.Open, outletID },
	});

	const status = getOrder[0]?.table?.status;

	useEffect(() => {
		if (status === TableStatus?.Available) {
			history.push(`/menu/patron-app/invalid-qr`);
		}
	}, [status]);

	const {
		data: { getOrderItem } = { getOrderItem: [] },
		loading: getOrderSummaryLoading,
	} = useGetOrderSummaryQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			status: OrderItemStatus.Pending,
		},
	});

	//GET OUTLET//
	//GET OUTLET//

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
			loadScheduledDailyMenuCategory({
				variables: {
					outletID: outletID,
					mealPeriodID: getCurrentMealPeriod?.ID,
				},
			});
		},
	});

	const {
		data: { getOutlet } = { getOutlet: [] },
		loading: getOutletLoading,
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: outletID,
		},
		onCompleted: ({ getOutlet }) => {
			setSelfOrder(getOutlet[0]?.customerPolicy?.selfOrder);
			setSelfPayment(getOutlet[0]?.customerPolicy?.selfPayment);
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		called: outletVisitorPatronCalled,
		loading: outletVisitorPatronLoading,
		error: outletVisitorPatronError,
		data: { getOutletVisitorPatron } = { getOutletVisitorPatron: {} as any },
	} = useGetOutletVisitorPatronQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	//GET CURRENT MEAL PERIOD FROM LOCAL STORAGE
	//GET CURRENT MEAL PERIOD FROM LOCAL STORAGE

	const [
		loadScheduledDailyMenuCategory,
		{
			loading: getScheduledDailyMenuCategoryLoading,
			called: getScheduledDailyMenuCategoryCalled,
			data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
		},
	] = useGetScheduledDailyMenuCategoryLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOutletDailyMenuCategory }) => {},
	});

	//get price based on patron ID
	//get price based on patron ID

	const [getPrice, setPatronPrice] = useState(null);

	const currTs = new Date();
	const currTimeInt = currTs.getHours() * 60 + currTs.getMinutes();

	const allRange = [];
	for (var i = 0; i <= 1440; i++) {
		allRange.push(i);
	}

	const getTimeRange = range => {
		if (range.startTime < range.endTime) {
			let timeRange = [];
			for (var i = range.startTime; i <= range.endTime; i++) {
				timeRange.push(i);
			}
			return timeRange;
		} else {
			let timeRange = [];
			for (var i = range.endTime + 1; i <= range.startTime; i++) {
				timeRange.push(i);
			}
			return allRange.filter(val => !timeRange.includes(val));
		}
	};

	useEffect(() => {
		if (currMenu?.promoChecking) {
			setPatronPrice(
				new BigNumber(
					listPatronPrice(currMenu, true) ?? 0,
					// currMenu?.outletDailyPromoMenuItem[0]?.outletDailyPromoMenuItemPrice?.filter(
					// 	id => id?.patronID === selectedOrder?.patronID,
					// )[0]?.price ?? 0,
				).toNumber(),
			);
		} else {
			setPatronPrice(
				new BigNumber(
					listPatronPrice(currMenu) ?? 0,
					// currMenu?.outletDailyMenuItemPatronPrice?.filter(
					// 	itemprice => itemprice?.patronID === selectedOrder?.patronID,
					// )[0]?.price ?? 0,
				).toNumber(),
			);
		}
	}, [currMenu]);

	const listPatronPrice = (item, promo?: Boolean) => {
		let price;
		let selfOrder = getOutlet[0]?.customerPolicy?.selfOrder;
		if (!promo && selfOrder) {
			price = item?.outletDailyMenuItemPatronPrice?.filter(
				itemprice => itemprice?.patronID === selectedOrder?.patronID,
			)[0]?.price;
		} else if (promo && selfOrder) {
			price = item?.outletDailyPromoMenuItem
				.filter(item => {
					const formattedExItem = {
						startTime:
							Number(item?.startTime.split(':')[0]) * 60 +
							Number(item?.startTime.split(':')[1]),
						endTime:
							Number(item?.endTime.split(':')[0]) * 60 +
							Number(item?.endTime.split(':')[1]),
					};
					//find if existing item overlap with current one
					if (getTimeRange(formattedExItem).includes(currTimeInt)) {
						return item;
					}
				})[0]
				?.outletDailyPromoMenuItemPrice?.filter(
					id => id?.patronID === selectedOrder?.patronID,
				)[0]?.price;
		} else if (!selfOrder) {
			price = item?.outletDailyMenuItemPatronPrice?.filter(
				itemprice => itemprice?.patronID === getOutletVisitorPatron?.ID,
			)[0]?.price;
		}
		return price;
	};

	const { data: { getUOM } = { getUOM: [] } } = useGetUomQuery({
		fetchPolicy: 'network-only',
		// variables: {
		// 	outletID: outletID,
		// },
	});

	let totalAmountFooter = amtStr(selectedOrder?.outstandingAmount);

	useEffect(() => {
		unregister(`option`);
		reset();
	}, [openMenu, openSetMenuDialog]);

	const [weightName, setWeightName] = useState(null);

	useEffect(() => {
		setWeightName(getUOM?.filter(id => id?.ID === currMenu?.uomID)[0]);
	}, [getOutletDailyMenuCategory, currMenu]);

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	useEffect(() => {
		setSelectedCategory(
			getOutletDailyMenuCategory?.filter(
				category => category?.ID === menuID,
			)[0],
		);
		setOriginalListing(
			getOutletDailyMenuCategory
				?.filter(category => category?.ID === menuID)[0]
				?.outletDailyMenuItem?.filter(
					item => item?.menuItem?.stopSales === null,
				)
				?.filter(x => x?.isOpenItem === false),
		);
		setSelectedIndex(
			getOutletDailyMenuCategory?.map(cat => cat?.ID)?.indexOf(menuID),
		);
		localStorage.setItem(
			'searchFilter',
			JSON.stringify({
				category: getOutletDailyMenuCategory?.filter(
					category => category?.ID === menuID,
				)[0]?.ID,
				searchString: menuSearch?.searchString ?? '',
			}),
		);
	}, [getOutletDailyMenuCategory, menuID]);

	//MUTATION
	//MUTATION

	const [optionErr, setOptionErr] = useState(false);

	useEffect(() => {
		setOptionErr(
			selectedCategory?.outletDailyMenuItem?.filter(
				id => id?.outletDailyMenuID === menuID,
			),
		);
	}, [selectedCategory]);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		createOrderItemWithOptions,
		{ called: createOptionCalled, loading: createOptionLoading },
	] = useCreateOrderItemWithOptionsMutation({
		onError: error => {
			setOptionErr(true);
		},
		onCompleted: data => {
			setOpenMenu(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setIsSubmitting(false);
		},
	});

	const [
		createOrderItemWithSetMenuOptions,
		{ called: createSetMenuOptionCalled, loading: createSetMenuOptionLoading },
	] = useCreateOrderItemWithSetMenuOptionsMutation({
		onError: err => {
			setOptionErr(true);
		},
		onCompleted: ({ createOrderItemWithSetMenuOptions }) => {
			setOpenSetMenuDialog(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setIsSubmitting(false);
		},
	});

	let amount: number = 0;
	const onSubmit = async data => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * getPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice) * quantity;
		} else {
			amount = getPrice * quantity;
		}
		createOrderItemWithOptions({
			variables: {
				orderItemInput: {
					outletID: outletID,
					name: currMenu?.name,
					description: currMenu?.description,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : quantity,
					unitPrice: parseFloat(data?.openPrice) || getPrice,
					menuItemID: currMenu?.menuItemID,
					openItemName: data?.openItem,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAway,
					weight: parseFloat(data?.getWeight) || 1,
				},
				orderItemOptionInput: data?.option?.map(orderOption => {
					let optionID = Object.keys(orderOption)[0];
					return {
						outletID: outletID,
						optionID: optionID,
						orderItemOptionItems:
							typeof orderOption[optionID][0] === 'string'
								? [
										{
											outletID: outletID,
											optionItemID: JSON.parse(orderOption[optionID][0])
												?.itemID,
											price:
												JSON.parse(orderOption[optionID][0])?.price * quantity,
											basePrice:
												JSON.parse(orderOption[optionID][0])?.price * quantity,
											unitPrice: JSON.parse(orderOption[optionID][0])?.price,
										},
								  ]
								: Object.keys(orderOption[optionID])
										?.filter(item => item !== null)
										?.map(optionItem => {
											return {
												outletID: outletID,
												optionItemID: optionItem,
												price: orderOption[optionID][optionItem] * quantity,
												basePrice: orderOption[optionID][optionItem] * quantity,
												unitPrice: orderOption[optionID][optionItem],
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetScheduledDailyMenuCategoryDocument,
					variables: {
						outletID: outletID,
						mealPeriodID: currentMealPeriod?.ID,
					},
				},
				{
					query: GetOrderDocument,
					variables: { ID: orderID, status: OrderStatus.Open, outletID },
				},
				{
					query: GetOrderSummaryDocument,
					variables: { orderID: orderID, status: OrderItemStatus.Pending },
				},
			],
		});
		setQuantity(1);
		setTakeAway(false);
	};

	//FILTER
	//FILTER
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	// useEffect(() => {
	// 	if (
	// 		selectedCategory?.outletDailyMenuItem &&
	// 		selectedCategory?.outletDailyMenuItem?.length > 0
	// 	) {
	// 		setOriginalListing(selectedCategory?.outletDailyMenuItem);
	// 	}
	// }, [selectedCategory?.outletDailyMenuItem]);

	//CHECK MENU BY WEIGHT PRICE ITEM SET MENU OR NORMAL
	//CHECK MENU BY WEIGHT PRICE ITEM SET MENU OR NORMAL

	const checkItem = menu => {
		if (menu?.isSetMenu) {
			setOpenSetMenuDialog(true);
		} else {
			setOpenMenu(true);
		}
	};

	const addSetMenuOrderItem = async (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * getPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice) * quantity;
		} else {
			amount = getPrice * quantity;
		}
		createOrderItemWithSetMenuOptions({
			variables: {
				orderItemInput: {
					unitPrice: parseFloat(data?.openPrice) || getPrice,
					outletID: outletID,
					name: currMenu?.name,
					description: currMenu?.description,
					amount: amount,
					baseAmount: amount,
					quantity: parseFloat(data?.getWeight) || quantity,
					menuItemID: currMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAway,
					openItemName: data?.openItem,
					weight: parseFloat(data?.getWeight) || 1,
				},
				orderItemSetMenuOptionInput: data?.option?.map(setMenuOption => {
					let setMenuOptionID = Object?.keys(setMenuOption)[0];
					return {
						outletID,
						setMenuOptionID: setMenuOptionID,
						orderItemSetMenuOptionItemInput:
							typeof setMenuOption[setMenuOptionID][0] === 'string'
								? [
										{
											outletID,
											menuItemID: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.menuItemID,
											setMenuOptionItemID: JSON.parse(
												setMenuOption[setMenuOptionID][0],
											)?.itemID,
											quantity: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.quantity,
										},
								  ]
								: Object.keys(setMenuOption[setMenuOptionID])
										?.filter(setMenuOptionItemID => setMenuOptionItemID != null)
										?.map(setMenuOptionItemID => {
											return {
												outletID,
												setMenuOptionItemID: setMenuOptionItemID,
												menuItemID:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														.menuItemID,
												quantity:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetScheduledDailyMenuCategoryDocument,
					variables: {
						outletID: outletID,
						mealPeriodID: currentMealPeriod?.ID,
					},
				},
				{
					query: GetOrderDocument,
					variables: { ID: orderID, status: OrderStatus.Open, outletID },
				},
				{
					query: GetOrderSummaryDocument,
					variables: { orderID: orderID, status: OrderItemStatus.Pending },
				},
			],
		});
		setQuantity(1);
		setTakeAway(false);
	};

	const MenuDialog = () => {
		return (
			<>
				<CommonDialogV2
					fullWidth={true}
					open={openMenu}
					onClose={() => {
						setOpenMenu(false);
						setQuantity(1);
						setTakeAway(false);
						setIsSubmitting(false);
            setAudioInput('');
					}}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">
												{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
											</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="title flex-space infoline">
											{currMenu?.name}
										</div>
										<div className="title rightText infoline">
											{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(getPrice)}`}
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<MultipleItemsCarousel>
									<div className="img-container">
										<img src={currMenu?.attachments?.fileURL} alt="" />
									</div>
								</MultipleItemsCarousel>
								<div className="orig-padding" style={{ marginTop: '10px' }}>
									<span className="mdDesc text-noflow">
										{currMenu?.description}
									</span>
									<hr className="line-description" />
									<div>
										{/* OPEN ITEM */}
										{/* OPEN ITEM */}

										{currMenu?.isOpenItem && selfOrder && (
											<div className="" style={{ marginBottom: '10px' }}>
												<span>
													<Controller
														as={TextField}
														fullWidth
														label="Menu Item"
														variant="standard"
														size="small"
														autoComplete="off"
														multiline={true}
														name="openItem"
														control={control}
														ref={register}
														helperText={errors?.openItem?.message}
														error={errors?.openItem ? true : false}
													/>
												</span>
											</div>
										)}

										{/* OPEN PRICE */}
										{/* OPEN PRICE */}

										{(currMenu?.isOpenItem || currMenu?.isOpenPrice) &&
											selfOrder && (
												<div className="" style={{ marginBottom: '20px' }}>
													<span>
														<Controller
															as={
																<NumberFormat
																	customInput={TextField}
																	fullWidth
																	label="Price"
																	autoComplete="off"
																	isNumericString
																	decimalScale={2}
																	fixedDecimalScale
																	style={{
																		marginBottom: '0px',
																		marginTop: '0px',
																	}}
																	margin="dense"
																	// ref={register}
																	// control={control}
																	allowNegative
																/>
															}
															name="openPrice"
															control={control}
															ref={register}
															helperText={errors?.openPrice?.message}
															error={errors?.openPrice ? true : false}
														/>
													</span>
												</div>
											)}

										{/* WIEGHT */}
										{/* WIEGHT */}
										{/* WIEGHT */}

										{currMenu?.isByWeight && selfOrder && (
											<Grid container style={{ marginBottom: '15px' }}>
												<Grid item xs={2}>
													<span className="mdDesc">
														<span>Weight</span>
													</span>
												</Grid>
												<Grid item xs={4}>
													<span className="dialog-span">
														<Controller
															as={
																<NumberFormat
																	customInput={TextField}
																	autoComplete="off"
																	isNumericString
																	fullWidth={false}
																	decimalScale={2}
																	fixedDecimalScale
																	style={{
																		marginBottom: '0px',
																		marginTop: '0px',
																	}}
																	margin="dense"
																	// ref={register}
																	// control={control}
																	defaultValue={1}
																	className="custom-add align-text"
																	allowNegative
																	// isAllowed={(value)=>{
																	// 	const { } = value
																	// 	return value !==0
																	// }}
																/>
															}
															name="getWeight"
															size="small"
															fullWidth={false}
															// label="Weight"
															variant="outlined"
															control={control}
															ref={register}
														/>
													</span>
												</Grid>
												<Grid item xs={2}>
													<span
														className="mdDesc"
														style={{ paddingLeft: '10px' }}
													>
														{`(${weightName?.name})`}
													</span>
												</Grid>
											</Grid>
										)}

										{/* QUANTITY */}
										{/* QUANTITY */}

										{(!currMenu?.isByWeight ||
											(!currMenu?.isByWeight && currMenu?.isOpenItem)) &&
											selfOrder && (
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => clickButton('reduce')}
													/>
													<span className="order-quantity">{quantity}</span>
													<AddBox
														className="order-add"
														onClick={() => clickButton('add')}
													/>
												</span>
											)}

										{selfOrder && (
											<span
												style={{
													float: 'right',
													position: 'relative',
													paddingTop: '10px',
												}}
											>
												<Checkbox
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
													style={{ padding: '5px' }}
													value={currMenu?.ID}
													onChange={e =>
														e.target.checked
															? setTakeAway(true)
															: setTakeAway(false)
													}
													ref={register}
												/>
												<span className="mdDesc">Take Away</span>
											</span>
										)}
										{currMenu?.menuItem?.menuItemOptions?.map((v, i) => (
											<>
												<div
													className="option-radio"
													style={{ paddingTop: '10px' }}
												>
													<div className="mdDesc" key={v?.menuItemID}>
														{v?.maxSelections === 1
															? `${v?.option?.name}* (Require 1 option)`
															: v?.maxSelections === 0
															? `${v?.option?.name}`
															: `${v?.option?.name}* (Choose at least 1 option)`}
													</div>

													{v?.maxSelections === 1
														? customRadio(
																v?.option?.optionItem,
																v?.option,
																i,
																v?.menuItemOptionItem,
																control,
																optionErr,
																register,
																watch,
														  )
														: customCheckBox(
																v?.option?.optionItem,
																v?.option,
																i,
																v?.menuItemOptionItem,
																clearErrors,
																optionErr,
																register,
																setValue,
																unregister,
																watch,
														  )}
												</div>
											</>
										))}

										{selfOrder && (
											<div className="mdDesc" style={{ padding: '10px 0px' }}>
												{'Special Request'}
											</div>
										)}
										{/* {specialRequestFunction()} */}
										{selfOrder && (
											<VoiceTextField
												name="specialRequest"
												mounted={true}
												label="We will try our best to fulfill your request..."
												required
												value={audioInput}
												record={record}
												setRecord={setRecord}
												setValue={setValue}
												audioInput={audioInput}
												setAudioInput={setAudioInput}
												openDialog={openMenu}
												customOnchange
												register={register}
												control={control}
												clearErrors={clearErrors}
												handleCustomOnChange={e => {
													setAudioInput(e.target.value);
												}}
											/>
										)}
									</div>
								</div>
							</>
						),
						footer: {
							actions: !selfOrder
								? [
										{
											displayText: 'Close',
											props: {
												onClick: () => {
													setOpenMenu(false);
													setQuantity(1);
													setTakeAway(false);
                          setAudioInput('');
												},
												variant: 'contained',
												color: 'primary',
											},
										},
								  ]
								: [
										{
											displayText: 'Cancel',
											props: {
												onClick: () => {
													setOpenMenu(false);
													setQuantity(1);
													setTakeAway(false);
                          setAudioInput('');
												},
												variant: 'contained',
												color: 'secondary',
											},
										},
										{
											displayText: 'Add',
											props: {
												onClick: e => {
													setIsSubmitting(true);
													if (currMenu?.menuItem?.menuItemOptions?.length > 0) {
														if (
															(currMenu?.isByWeight || quantity !== 0) &&
															watch(`option`)?.length ===
																currMenu?.menuItem?.menuItemOptions?.length
														) {
															!isSubmitting && handleSubmit(onSubmit)();
															setOptionErr(false);
														} else {
															setOptionErr(true);
														}
													} else {
														!isSubmitting && handleSubmit(onSubmit)();
													}
													// quantity !== 0 && handleSubmit(onSubmit)();
													e.stopPropagation();
                          setAudioInput('');
												},
												variant: 'contained',
												color:
													quantity !== 0 || currMenu?.isByWeight
														? 'primary'
														: 'default',
												disabled: isSubmitting,
											},
										},
								  ],
						},
					}}
				/>
			</>
		);
	};

	const customLabel = (optionItemName: any, checked: boolean) => {
		return (
			<>
				{checked ? (
					<div className="xsTitle custom-label-checked">{optionItemName}</div>
				) : (
					<div className="xsTitle custom-label">{optionItemName}</div>
				)}
			</>
		);
	};

	const customRadio = (
		data: any,
		option: any,
		optionIndex: number,
		menuItemOptionItem,
		control: any,
		optionErr: any,
		register: any,
		watch: any,
	) => {
		return (
			<>
				<span>
					{menuItemOptionItem?.map(
						(optionItem: any, optionItemIndex: number) => (
							<Controller
								as={
									<RadioGroup>
										<Radio
											checked={checkedRadioCondition(
												watch,
												optionIndex,
												option,
												optionItem.optionItemID,
											)}
											value={JSON.stringify({
												itemID: optionItem?.optionItemID,
												price: optionItem?.price,
											})}
											name={optionItem?.optionItem?.name}
											checkedIcon={customLabel(
												optionItem?.optionItem?.name,
												true,
											)}
											icon={customLabel(optionItem?.optionItem?.name, false)}
											ref={register}
											disabled={!selfOrder}
										/>
									</RadioGroup>
								}
								name={`option[${optionIndex}].${option?.ID}[0]`}
								control={control}
								ref={register}
							/>
						),
					)}
				</span>
				<div>
					<TextField
						InputProps={{ disableUnderline: true }}
						disabled
						style={{ marginTop: '-35px' }}
						helperText={
							optionErr &&
							selfOrder &&
							watch(`option[${optionIndex}].${option?.ID}[0]`) === undefined
								? 'Must select atleast 1 option'
								: null
						}
						error={optionErr}
					/>
				</div>
			</>
		);
	};

	const checkedRadioCondition = (
		watch: any,
		optionIndex: number,
		option: any,
		optionItemID: string,
	) => {
		let optionItemIDandPrice: any = watch(
			`option[${optionIndex}].${option?.ID}[${0}]`,
		);
		if (optionItemIDandPrice && JSON.parse(optionItemIDandPrice)?.itemID) {
			return optionItemID === JSON.parse(optionItemIDandPrice)?.itemID;
		} else return false;
	};

	const customCheckBox = (
		data: any,
		option: any,
		optionIndex: number,
		menuItemOptionItem: any,
		clearErrors: any,
		optionErr: any,
		register: any,
		setValue: any,
		unregister: any,
		watch: any,
	) => {
		return (
			<>
				<div>
					{menuItemOptionItem?.map((optionItem, optionItemIndex) => (
						<FormControl component="fieldset">
							<Checkbox
								checkedIcon={customLabel(optionItem?.optionItem?.name, true)}
								icon={customLabel(optionItem?.optionItem?.name, false)}
								onChange={event => {
									register(
										`option[${optionIndex}].${option?.ID}[${event.target.value}]`,
									);
									if (event.target.checked) {
										setValue(
											`option[${optionIndex}].${option?.ID}[${event.target.value}]`,
											optionItem?.price,
										);
									} else {
										unregister(
											`option[${optionIndex}].${option?.ID}[${event.target.value}]`,
										);
									}
								}}
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								ref={register}
								value={optionItem?.optionItemID}
								name={`option[${optionIndex}].${option?.ID}[${optionItemIndex}]`}
								disabled={!selfOrder}
							/>
						</FormControl>
					))}
				</div>
				<div>
					<TextField
						InputProps={{ disableUnderline: true }}
						disabled
						style={{ marginTop: '-35px' }}
						helperText={
							optionErr &&
							selfOrder &&
							watch(`option[${optionIndex}].${option?.ID}`) === undefined
								? 'Must Select atleast 1 option'
								: null
						}
						error={optionErr}
					/>
				</div>
			</>
		);
	};

	const SetMenuDialog = () => {
		return (
			<>
				<CommonDialogV2
					fullWidth={true}
					open={openSetMenuDialog}
					onClose={() => {
						setOpenSetMenuDialog(false);
						setQuantity(0);
						setTakeAway(false);
						setOptionErr(false);
						setIsSubmitting(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">
												{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
											</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="title flex-space infoline">
											{currMenu?.name}
										</div>
										<div className="title rightText infoline">
											{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(getPrice)}`}
										</div>
									</div>
								</div>
							),
							// children: (
							// 	<>
							// 		<ListItem
							// 			style={{ backgroundColor: '#c7d6ff' }}
							// 			className="remove-padding"
							// 		>
							// 			<ListItemText
							// 				primary={
							// 					<>
							// 						<div
							// 							className="smTitle highlight-text"
							// 							style={{ color: '#ff9800' }}
							// 						>
							// 							<span
							// 								style={{ marginRight: '15px' }}
							// 							>{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}</span>
							// 						</div>
							// 					</>
							// 				}
							// 				secondary={
							// 					<>
							// 						<span className="highlight-text flex-space">
							// 							{currMenu?.name}
							// 						</span>
							// 						<span className="highlight-text">
							// 							{`$${amtStr(getPrice)}`}
							// 						</span>
							// 					</>
							// 				}
							// 			/>
							// 		</ListItem>
							// 		<hr className="line-subheader" />
							// 	</>
							// ),
						},
						body: () => (
							<>
								<MultipleItemsCarousel>
									<div className="img-container">
										<img src={currMenu?.attachments?.fileURL} alt="" />
									</div>
								</MultipleItemsCarousel>
								<div className="orig-padding" style={{ marginTop: '10px' }}>
									<span className="mdDesc text-noflow">
										{currMenu?.description}
									</span>
									<hr className="line-description" />

									<div>
										{/* QUANTITY */}
										{/* QUANTITY */}
										{(!currMenu?.isByWeight ||
											(!currMenu?.isByWeight && currMenu?.isOpenItem)) &&
											selfOrder && (
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => clickButton('reduce')}
													/>
													<span className="order-quantity">{quantity}</span>
													<AddBox
														className="order-add"
														onClick={() => clickButton('add')}
													/>
												</span>
											)}
										{selfOrder && (
											<span
												style={{
													float: 'right',
													position: 'relative',
													paddingTop: '10px',
												}}
											>
												<Checkbox
													value={currMenu?.ID}
													onChange={e =>
														e.target.checked
															? setTakeAway(true)
															: setTakeAway(false)
													}
													color="primary"
													style={{ padding: '5px' }}
													inputProps={{
														'aria-label': 'secondary checkbox',
													}}
													ref={register}
												/>

												<span className="mdDesc">Take Away</span>
											</span>
										)}
										<div style={{ padding: '10px 0px' }}>
											<div
												style={{ paddingBottom: '5px' }}
												className="desc"
											>{`Included in ${currMenu?.name}`}</div>
											{currMenu?.menuItem?.setMenuOption
												?.filter(option => option?.maxSelections === 0)[0]
												?.setMenuOptionItem?.map((optionItem, i) => (
													<div
														style={{ paddingBottom: '5px' }}
														className="xsTitle"
													>{`${optionItem?.menuItem?.name} x${optionItem?.quantity}`}</div>
												))}
										</div>
										{!!currMenu?.menuItem?.setMenuOption &&
											currMenu?.menuItem?.setMenuOption
												?.filter(
													setMenuOption => setMenuOption?.maxSelections !== 0,
												)
												?.map((setMenuOption, i) => (
													<div className="option-radio">
														<div className="mdDesc" key={setMenuOption?.ID}>
															{`${setMenuOption?.name}* (Choose at least ${setMenuOption?.maxSelections} option)`}
														</div>
														{setMenuOption?.maxSelections === 1
															? customSetMenuRadio(
																	setMenuOption?.setMenuOptionItem,
																	setMenuOption,
																	i + 1,
															  )
															: setMenuOption?.maxSelections > 1
															? customSetMenuCheckBox(
																	setMenuOption?.setMenuOptionItem,
																	setMenuOption,
																	i + 1,
															  )
															: null}
													</div>
												))}
									</div>
								</div>
							</>
						),
						footer: {
							actions: !selfOrder
								? [
										{
											displayText: 'Close',
											props: {
												onClick: () => {
													setOpenSetMenuDialog(false);
													setQuantity(0);
													setTakeAway(false);
												},
												variant: 'contained',
												color: 'primary',
											},
										},
								  ]
								: [
										{
											displayText: 'Cancel',
											props: {
												onClick: () => {
													setOpenSetMenuDialog(false);
													setQuantity(0);
													setTakeAway(false);
												},
												variant: 'contained',
												color: 'secondary',
											},
										},
										{
											displayText: 'Add',
											props: {
												onClick: e => {
													setIsSubmitting(true);
													if (currMenu?.menuItem?.setMenuOption?.length > 0) {
														if (
															quantity !== 0 &&
															watch(`option`)?.length ===
																currMenu?.menuItem?.setMenuOption?.length
														) {
															!isSubmitting &&
																handleSubmit(addSetMenuOrderItem)();
														} else {
															setOptionErr(true);
														}
													} else {
														!isSubmitting &&
															handleSubmit(addSetMenuOrderItem)();
													}
													e.stopPropagation();
												},
												color: quantity !== 0 ? 'primary' : 'default',
												variant: 'contained',
												type: 'submit',
												disabled: isSubmitting,
											},
										},
								  ],
						},
					}}
				/>
			</>
		);
	};

	const customSetMenuCheckBox = (
		setMenuOptionItem: any,
		setMenuOption: any,
		setMenuOptionIndex: number,
	) => {
		return (
			<>
				<div>
					{setMenuOptionItem?.map(
						(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
							<FormControl component="fieldset">
								<Checkbox
									checkedIcon={customLabel(
										setMenuOptionItem?.menuItem?.name,
										true,
									)}
									icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
									onChange={e => {
										register(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
										);
										if (e.target.checked) {
											setValue(
												`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
												{
													quantity: setMenuOptionItem?.quantity || 1,
													menuItemID: setMenuOptionItem?.menuItem?.ID,
												},
											);
										} else {
											unregister(
												`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
											);
										}
									}}
									color="primary"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									ref={register}
									value={setMenuOptionItem?.ID}
									name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItemIndex}]`}
									disabled={!selfOrder}
								/>
							</FormControl>
						),
					)}
				</div>
				<div>
					<TextField
						InputProps={{ disableUnderline: true }}
						disabled
						//
						style={{ marginTop: '-35px' }}
						helperText={
							optionErr &&
							selfOrder &&
							watch(`option[${setMenuOptionIndex}].${setMenuOption?.ID}`) ===
								undefined
								? 'Must Select atleast 1 option'
								: null
						}
						error={optionErr}
					/>
				</div>
			</>
		);
	};

	useEffect(() => {
		if (openSetMenuDialog) {
			currMenu?.menuItem?.setMenuOption
				?.filter(setMenuOption => setMenuOption?.maxSelections === 0)[0]
				?.setMenuOptionItem?.map((setMenuOptionItem, i) => {
					register(
						`option[0].${
							currMenu?.menuItem?.setMenuOption?.filter(
								setMenuOption => setMenuOption?.maxSelections === 0,
							)[0].ID
						}[${setMenuOptionItem?.ID}]`,
					);
					setValue(
						`option[0].${
							currMenu?.menuItem?.setMenuOption?.filter(
								setMenuOption => setMenuOption?.maxSelections === 0,
							)[0].ID
						}[${setMenuOptionItem?.ID}]`,
						{
							quantity: setMenuOptionItem?.quantity,
							menuItemID: setMenuOptionItem?.menuItem?.ID,
						},
					);
				});
		}
	}, [currMenu, openSetMenuDialog]);

	const customSetMenuRadio = (
		setMenuOptionItem: any,
		setMenuOption: any,
		setMenuOptionIndex: number,
	) => {
		return (
			<>
				<span>
					{setMenuOptionItem?.map(
						(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
							<Controller
								as={
									<RadioGroup>
										<Radio
											checked={checkedRadioCondition(
												watch,
												setMenuOptionIndex,
												setMenuOption,
												setMenuOptionItem.ID,
											)}
											value={JSON.stringify({
												itemID: setMenuOptionItem?.ID,
												quantity: setMenuOptionItem?.quantity || 1,
												menuItemID: setMenuOptionItem?.menuItem?.ID,
											})}
											name={setMenuOptionItem?.menuItem?.name}
											checkedIcon={customLabel(
												setMenuOptionItem?.menuItem?.name,
												true,
											)}
											icon={customLabel(
												setMenuOptionItem?.menuItem?.name,
												false,
											)}
											ref={register}
											disabled={!selfOrder}
										/>
									</RadioGroup>
								}
								name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`}
								control={control}
								ref={register}
								// helperText={errors?.calcType?.message}
								// error={errors?.calcType ? true : false}
							/>
						),
					)}
				</span>
				<div>
					<TextField
						InputProps={{ disableUnderline: true }}
						disabled
						style={{ marginTop: '-35px' }}
						helperText={
							optionErr &&
							selfOrder &&
							watch(`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`) ===
								undefined
								? 'Must select atleast 1 option'
								: null
						}
						error={optionErr}
					/>
				</div>
			</>
		);
	};

	useEffect(() => {
		if (
			!getScheduledDailyMenuCategoryLoading &&
			selectedCategory?.outletDailyMenuItem?.length > 0 &&
			!!menuSearch?.searchString
		) {
			handleSearch(`'"${menuSearch?.searchString ?? ''}"`, ['name']);
		}
	}, [getScheduledDailyMenuCategoryLoading, selectedCategory]);

	const categoryItemCount = items => {
		const filterItem = items?.filter(
			item => item?.menuItem?.stopSales === null,
		);
		return filterItem?.length;
	};

	return (
		<>
			{getOrderLoading && <Loading />}
			{getOrderSummaryLoading && <Loading />}
			{getOutletLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}

			<MainHeaderCustomer
				onClick={() =>
					history.push({
						pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}`,
						//pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}`,
					})
				}
				mainBtn="back"
				smTitle="Customer App"
				title={getOutlet[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + selectedOrder?.table?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				//"Table 03 (HARDCODE)"
				routeSegments={[
					//{ name: 'Table Layout' },
					{ name: 'Order', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTimeto12h(
									currentMealPeriod?.startTime,
							  )} - ${formatTimeto12h(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			{getOutlet[0]?.customerPolicy?.selfOrder && (
				<OrderSubheader
					getOrderLoading={getOrderLoading}
					getOrder={getOrder}
					selectedOrder={selectedOrder}
					orderID={selectedOrder?.ID}
					outletID={outletID}
				/>
			)}

			<ContentWrapper
				float
				style={
					getOutlet[0]?.customerPolicy?.selfOrder
						? {
								marginTop: isDesktop ? '50px' : '72px',
						  }
						: {
								marginTop: isDesktop ? '15px' : '37px',
						  }
				}
			>
				<div
					className="search-filter with-dropdown-filter-customer"
					style={{
						top: !getOutlet[0]?.customerPolicy?.selfOrder ? '75px' : null,
					}}
				>
					<Grid container>
						<Grid item xs={7} style={{ paddingBottom: '4px', marginLeft: '0' }}>
							<SearchHeader
								title="Menu Item Listing "
								value={`${filteredList?.filter(e => {
									if (selectedCategory?.outletDailyMenuItem) {
										return e;
									}
								})?.length ?? '0'}`}
								defaultValue={
									menuSearch?.searchString ? menuSearch?.searchString : ''
								}
								onChangeAction={e => {
									handleSearch(`'"${e?.target?.value}"`, ['name']);
									localStorage.setItem(
										'searchFilter',
										JSON.stringify({
											category: menuSearch?.category,
											searchString: e?.target?.value,
										}),
									);
								}}
								search
								isDefaultValue={!!menuSearch?.searchString ?? false}
								onCloseAction={() => {
									localStorage.setItem(
										'searchFilter',
										JSON.stringify({
											category: menuSearch?.category ?? '',
											searchString: '',
										}),
									);
									handleSearch('', []);
								}}
								clickText
							/>
						</Grid>
						<Grid item xs={5} style={{ paddingBottom: '4px' }}>
							<List
								disablePadding
								className="search-header"
								style={{ borderRadius: '4px' }}
							>
								<ListItem
									button
									aria-haspopup="true"
									aria-controls="lock-menu"
									onClick={handleClickListItem}
									style={{ minHeight: '38px' }}
								>
									<ListItemText
										disableTypography
										primary={
											<span className="xsTitle">
												{getOutletDailyMenuCategory[selectedIndex]?.name ??
													'Category'}
											</span>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="arrow"
											onClick={handleClickListItem}
										>
											<KeyboardArrowDownIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</List>
							<Menu
								id="lock-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={categoryDDClose}
								// onClose={categoryDDClose}
							>
								{getOutletDailyMenuCategory
									?.filter(
										cat => categoryItemCount(cat?.outletDailyMenuItem) !== 0,
									)
									?.map((e, index) => (
										<MenuItem
											key={index}
											value={e.ID}
											onClick={() => handleMenuCategoryClick(e, index)}
										>
											{e.name}
										</MenuItem>
									))}
							</Menu>
						</Grid>
					</Grid>
				</div>

				<MsgAlert
					icon={<TouchAppIcon style={{ color: '#FFB11F', fontSize: '20px' }} />}
					message={'Tick to Select Menu Item'}
				/>

				<List className="core-list" style={{ marginBottom: '100px' }}>
					{!getOrderLoading &&
					!getOrderSummaryLoading &&
					!getOutletLoading &&
					!getCurrentMealPeriodLoading &&
					!getScheduledDailyMenuCategoryLoading &&
					(getOutletDailyMenuCategory === undefined ||
						getOutletDailyMenuCategory?.length === 0) ? (
						<EmptyList
							title="No menu item under this category"
							subtitle="Please select a different category to continue"
						/>
					) : (
						filteredList
							// ?.filter(e => {
							// 	if (
							// 		selectedCategory?.outletDailyMenuItem?.filter(
							// 			item =>
							// 				listPatronPrice(item) !== undefined &&
							// 				listPatronPrice(item) !== 0,
							// 		)
							// 	) {
							// 		return e;
							// 	}
							// })
							// selectedCategory?.outletDailyMenuItem
							// 	?.filter(
							// 		item =>
							// 			listPatronPrice(item) !== undefined &&
							// 			listPatronPrice(item) !== 0,
							// 	)
							?.map((v, i) => (
								// <>
								// 	{v?.menuItem?.stopSales?.isActive ? (
								// 		<div></div>
								// 	) : (
								<ListItem
									style={{
										padding: '0px 10px 0px',
										backgroundColor:
											v?.menuItem?.stopSales?.isActive ||
											v?.menuItem?.dailyAvailability?.outstandingQty < 0
												? '#ffc0c0'
												: null,
									}}
									disabled={
										v?.menuItem?.stopSales?.isActive ||
										v?.menuItem?.dailyAvailability?.outstandingQty < 0
											? true
											: null
									}
								>
									<div
										className="container"
										style={{
											paddingRight: '6px',
										}}
									>
										{/* <img
													src={v?.attachments?.fileURL}
													className="img-owned"
													loading="lazy"
													alt="Item"
												/> */}
										<ListItemIcon>
											{v?.attachments?.fileURL ? (
												<img
													className="img-owned"
													src={v?.attachments?.fileURL}
												/>
											) : (
												<img
													className="img-owned"
													style={{ border: 'none' }}
													src={NoImage}
												/>
											)}
										</ListItemIcon>
										{(v?.menuItem?.stopSales?.isActive ||
											v?.menuItem?.dailyAvailability?.outstandingQty < 0) && (
											<div className="img-sold-out-text">SOLD</div>
										)}
										{v?.promoChecking && (
											<div className="img-promo-text">PROMO</div>
										)}
									</div>
									<ListItemText
										primary={
											<>
												{v?.isOpenPrice || v?.isOpenItem ? (
													<span className="xsTitle">
														{v?.isOpenItem
															? 'Open Food Item'
															: 'Open Price Item'}
													</span>
												) : (
													<span className="xsTitle">{v?.name}</span>
												)}
												{v?.isChefRecommended && (
													<ThumbUp
														htmlColor="white"
														fontSize="small"
														style={{
															width: '18px',
															height: '18px',
															color: '#ff9800',
															marginLeft: '4px',
														}}
													/>
												)}
											</>
										}
										secondary={
											<>
												<span className="flex-space desc">
													<div>{v?.description}</div>

													<div>
														{v?.isByWeight ? (
															<span className="highlight-text">{`${getOutlet[0]
																?.currencyCode ?? ''}${amtStr(
																new BigNumber(listPatronPrice(v)).toNumber(),
															)}/${
																getUOM?.filter(id => id?.ID === v?.uomID)[0]
																	?.name
															}`}</span>
														) : v?.promoChecking ? (
															<>
																<span className="highlight-text">
																	{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
																		new BigNumber(
																			listPatronPrice(v, true),
																		).toNumber(),
																	)}`}
																</span>
																<span
																	style={{
																		marginLeft: '8px',
																		textDecoration: 'line-through',
																		fontWeight: 'bolder',
																	}}
																>
																	{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
																		new BigNumber(
																			listPatronPrice(v),
																		).toNumber(),
																	)}`}
																</span>
															</>
														) : (
															<span className="highlight-text">
																{getOutlet[0]?.currencyCode ?? ''}
																{amtStr(
																	new BigNumber(listPatronPrice(v)).toNumber(),
																)}
															</span>
														)}
													</div>
												</span>
											</>
										}
									/>

									<AddBox
										style={{
											fontSize: '32px',
											paddingTop: '28px',
											color: '#ff9800',
											//v?.menuItem?.stopSales?.isActive ||
											//v?.menuItem?.dailyAvailability?.outstandingQty < 0
											//? 'dimgray'
											//: '#ff9800',
										}}
										onClick={() => {
											checkItem(v);
											// setOpenSetMenuDialog(true);
											//setOpenMenu(true);
											setCurrMenu(v);
											//if (
											//!v?.menuItem?.stopSales?.isActive &&
											//v?.menuItem?.dailyAvailability?.outstandingQty >= 0
											//) {
											//	setOpenMenu(true);
											//	setCurrMenu(v);
											//}
										}}
									/>
								</ListItem>
								// 	)}
								// </>
							))
					)}
					{/* ))} */}
				</List>
			</ContentWrapper>
			{getOutlet[0]?.customerPolicy?.selfOrder && (
				<PatronFooter
					text="Total"
					value={`${getOutlet[0]?.currencyCode ?? ''}${totalAmountFooter || 0}`}
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					leftBtn={{
						name: `View Orders (${getOrderItem?.length ?? 0})`,
						onClick: () => {
							history.push(
								getOrderItem?.length > 0
									? `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary`
									: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order`,
							);
						},
						style: {
							color: 'white',
							backgroundColor: 'orange',
							padding: '6px 16px',
						},
					}}
				/>
			)}
			{MenuDialog()}
			{SetMenuDialog()}
		</>
	);
};
