import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { IFCAGlobeIcon } from 'assets';
import { MainHeaderCustomer } from 'components/Layout/MainHeaderCustomer';
import AppContext from 'containers/App/Store/AppContext';
import {
	OrderStatus,
	TableStatus,
	useGetCurrentMealPeriodLazyQuery,
	useGetDailyMenuCategoryLazyQuery,
	useGetOrderQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { formatTimeto12h } from 'helpers/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrderSubheader } from '../../../src/components/Subheader/OrderSubheader';
import { PatronFooter } from '../../components/Footer/PatronFooter';

export const MenuList = () => {
	useEffect(() => {
		console.log('MenuList Customer App Pos-React-Customer');
	}, []);
	const history = useHistory();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [remainingItem, setRemainingItem] = useState([]);

	const { outletID, tableID, orderID } = useParams<Record<string, any>>();

	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		data: { getOrder } = { getOrder: [] },
		called: getOrderCalled,
		loading: getOrderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: { ID: orderID, status: OrderStatus.Open, outletID },
		onCompleted: ({ getOrder }) => {
			let orderItem = getOrder[0]?.orderItem;
			let temp = {};
			orderItem?.map(el => {
				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID] += el?.quantity * -1;
					} else {
						temp[el?.refID] = el?.quantity * -1;
					}
				}
			});
			setRemainingItem(
				orderItem?.filter(v => {
					return v?.quantity - (temp[v?.ID] ?? 0) > 0;
				}),
			);
		},
	});

	const status = getOrder[0]?.table?.status;

	useEffect(() => {
		if (status === TableStatus?.Available) {
			history.push(`/menu/patron-app/invalid-qr`);
		}
	}, [status]);

	const [selectedOrder, setSelectedOrder] = useState(
		getOrder?.filter(order => order?.ID === orderID)[0],
	);

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
			loadOutletDailyMenuCategory({
				variables: {
					outletID: outletID,
					mealPeriodID: getCurrentMealPeriod?.ID,
				},
			});
		},
	});

	const [
		loadOutletDailyMenuCategory,
		{
			data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
			loading: getDailyMenuCategoryLoading,
		},
	] = useGetDailyMenuCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const categoryItemCount = items => {
		const filterItem = items?.filter(
			item => item?.menuItem?.stopSales === null,
		);
		return filterItem?.length;
	};

	return (
		<>
			{outletLoading && <Loading />}
			{getOrderLoading && <Loading />}
			{getDailyMenuCategoryLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeaderCustomer
				onClick={() => history.push(`/menu/patron-app/qr-code`)}
				mainBtn="ifca"
				customIcon={IFCAGlobeIcon}
				smTitle="Customer App"
				title={getOutlet[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + getOrder[0]?.table?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				routeSegments={[{ name: 'Menu List', current: true }]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTimeto12h(
									currentMealPeriod?.startTime,
							  )} - ${formatTimeto12h(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			{/* <DynamicSubHeader
				noMainheader
				title={
					getOrder === undefined || getOrder?.length === 0
						? ''
						: `${getOrder[0]?.table?.prefix}${getOrder[0]?.table?.tableNumber} ${getOrder[0]?.docNo}`
				}
				rightText={`$${amtStr(getOrder[0]?.outstandingAmount)}`}
			/> */}

			{getOutlet[0]?.customerPolicy?.selfOrder && (
				<OrderSubheader
					getOrderLoading={getOrderLoading}
					getOrder={getOrder}
					selectedOrder={selectedOrder}
					orderID={selectedOrder?.ID}
					outletID={outletID}
				/>
			)}

			<ContentWrapper
				customerDynamicInfo
				style={{
					marginTop: `${
						!getOutlet[0]?.customerPolicy?.selfOrder ? '0px' : '30px'
					}`,
				}}
			>
				<List className="core-list">
					{!outletLoading &&
					!getOrderLoading &&
					!getDailyMenuCategoryLoading &&
					!getCurrentMealPeriodLoading &&
					(getCurrentMealPeriod === undefined ||
						getCurrentMealPeriod === null) ? (
						<EmptyList
							title={`No applicable current meal period ath the moment`}
						/>
					) : [...getOutletDailyMenuCategory]?.filter(
							cat => categoryItemCount(cat?.outletDailyMenuItem) !== 0,
					  )?.length === 0 ? (
						<EmptyList title={`No menu during the current meal period`} />
					) : (
						[...getOutletDailyMenuCategory]
							?.sort(function(a, b) {
								return a.priority - b.priority;
							})
							?.filter(cat => categoryItemCount(cat?.outletDailyMenuItem) !== 0)
							?.map((el, index) => (
								<ListItem
									onClick={() => {
										history.push({
											pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${el.ID}`,
										});
									}}
								>
									<ListItemText
										primary={
											<>
												<Grid className="smTitle flex-space">
													<Grid style={{ textAlign: 'left' }}>
														{`${index + 1}. ${el?.name} `}
													</Grid>
													{/* <Grid item xs={3}>
													
												</Grid> */}
												</Grid>
											</>
										}
										secondary={
											<>
												<Grid
													container
													className="desc"
													style={{ paddingLeft: '11px' }}
												>
													{/* <Grid item xs={2} style={{ textAlign: 'center' }}> */}
													{`No. of Item : ${categoryItemCount(
														el?.outletDailyMenuItem,
													) ?? 0}`}

													{/* </Grid> */}
												</Grid>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton edge="end">
											<KeyboardArrowRight
												onClick={() => {
													history.push({
														pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${el.ID}`,
													});
												}}
											/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
			</ContentWrapper>

			{getOutlet[0]?.customerPolicy?.selfOrder && (
				<PatronFooter
					text="Total"
					value={`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
						getOrder[0]?.outstandingAmount,
					) || 0}`}
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					leftBtn={{
						name: `View Orders (${remainingItem.length ?? 0})`,
						onClick: () => {
							history.push(
								`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${getOutletDailyMenuCategory[0]?.ID}/summary`,
							);
						},
						style: { color: 'white', backgroundColor: 'orange' },
					}}
				/>
			)}
		</>
	);
};
