import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import {
	PatronClass,
	PaymentClass,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListLazyQuery,
} from 'generated/graphql';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { IAction } from 'helpers/model';
import { amtStr } from 'helpers/numFormatter';
import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { Controller } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import {
	PaymentDialogProps,
	ReasonProps,
	ReducerProps,
	SignatureProps,
} from './DialogInterface';

export const PaymentDialogComponent = (props: PaymentDialogProps) => {
	const {
		selectedRoom,
		setSelectedRoom,
		openDialog,
		paymentType,
		setOpenDialog,
		getDefaultPayment,
		control,
		errors,
		register,
		hotelID,
		outletID,
		defaultRoom,
		defaultOfficer,
		defaultStaff,
		openReason,
		openSignature,
		selectedOfficer,
		selectedStaff,
		setDefaultOfficer,
		setDefaultStaff,
		setSelectedOfficer,
		setSelectedStaff,
		setDefaultRoom,
		defaultDebtor,
		setDefaultDebtor,
		selectedDebtor,
		setSelectedDebtor,
		selectedOrder,
		total,
		setValue,
		watch,
		radioError,
		setRadioError,
		selectedPaymentClass,
		handleSubmit,
		setOpenReason,
		onSubmit,
		setOpenSignature,
		selectedCredit,
		setSelectedCredit,
	} = props;
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [tempState, setTempState] = useState('');

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	//reducer-----------------------------------------
	const initialState = {
		cardDropDown: false,
		cashAmount: false,
		hotelSearchField: false,
		debtorSearchField: false,
		officerSearchField: false,
		staffSearchField: false,
	};

	const reducer: Reducer<ReducerProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	//reducer-----------------------------------------
	//reducer-----------------------------------------

	//hotel stuff query idk i just make the component------------------------
	//hotel stuff query idk i just make the component------------------------

	const [
		loadMenuXInhouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXInhouseList }) => {
			if (selectedOrder?.patronAccountID !== null) {
				setDefaultRoom(
					getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
						x => x?.RegistrationID === selectedOrder?.patronAccountID,
					)[0],
				);
			}
		},
	});

	const [
		loadMenuXDebtorList,
		{
			loading: MenuXDebtorListLoading,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXDebtorList }) => {
			if (selectedOrder?.registrationID !== null) {
				setDefaultDebtor(
					getMenuXDebtorList?.data?.MenuXInDebtorList?.filter(
						x => x?.RegistrationID === selectedOrder?.registrationID,
					)[0],
				);
			}
		},
	});

	// const [
	// 	loadOfficer,
	// 	{ loading: loadingOfficer, data: { getOfficer } = { getOfficer: [] } },
	// ] = useGetOfficerLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: ({ getOfficer }) => {
	// 		let selectedOrder;
	// 		if (paymentType === 'voidPayment') {
	// 			selectedOrder = selectedOrder?.order;
	// 		}
	// 		if (selectedOrder?.patronAccountID !== null) {
	// 			setDefaultOfficer(
	// 				getOfficer?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0],
	// 			);
	// 		}
	// 	},
	// });

	// const [
	// 	loadStaff,
	// 	{ loading: loadingStaff, data: { getStaff } = { getStaff: [] } },
	// ] = useGetStaffLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	onCompleted: () => {
	// 		let selectedOrder;
	// 		if (paymentType === 'voidPayment') {
	// 			selectedOrder = selectedOrder?.order;
	// 		}
	// 		if (selectedOrder?.patronAccountID !== null) {
	// 			setDefaultStaff(
	// 				getOfficer?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0],
	// 			);
	// 		}
	// 	},
	// });
	//hotel stuff query idk i just make the component------------------------
	//hotel stuff query idk i just make the component------------------------

	const customerQuery = query => {
		let data = query?.filter(v => {
			if (
				v?.paymentClass === 'ONLINE' ||
				(v?.paymentClass === 'ROOM' &&
					selectedOrder?.patron?.patronClass.toUpperCase() === 'HOTEL GUEST') ||
				(v?.paymentClass === 'MEMBER' &&
					selectedOrder?.patron?.patronClass.toUpperCase() ===
						PatronClass.Member)
			) {
				return v;
			} else return null;
		});
		return data;
	};

	const handleRadio = v => {
		const defaultState = [
			{
				type: PaymentClass.Credit,
				name: 'cardDropDown',
			},
			{
				type: PaymentClass.Cash,
				name: 'cashAmount',
			},
			{
				type: PaymentClass.Room,
				name: 'hotelSearchField',
			},
			{
				type: PaymentClass.Debtor,
				name: 'debtorSearchField',
			},
		];

		defaultState?.map(d => {
			if (d?.type === v?.paymentClass) {
				dispatch({
					type: d?.name,
					payload: true,
				});
				if (v?.paymentClass === PaymentClass.Room) {
					// setSelectedRoom()
				}
			} else {
				dispatch({
					type: d?.name,
					payload: false,
				});
			}
		});
	};

	// const creditCardText = data => {
	// 	let credClass;
	// 	if (
	// 		data === CreditCardType.AmericanExpressCard ??
	// 		data === 'American Express Card'
	// 	) {
	// 		credClass = 'American Express Card';
	// 	} else if (data === CreditCardType.DinersCard ?? data === 'Diners Card') {
	// 		credClass = 'Diners Card';
	// 	} else if (data === CreditCardType.JcbCard ?? data === 'JCB Card') {
	// 		credClass = 'JCB Card';
	// 	} else if (data === CreditCardType.Mastercard ?? data === 'Master Card') {
	// 		credClass = 'Master Card';
	// 	} else if (data === CreditCardType.UnionpayCard ?? data === 'UNION PAY') {
	// 		credClass = 'UNION PAY';
	// 	} else if (data === CreditCardType.VisaCard ?? data === 'Visa Card') {
	// 		credClass = 'Visa Card';
	// 	} else if (data === CreditCardType.DebitCard ?? data === 'Debit Card') {
	// 		credClass = 'Visa Card';
	// 	}
	// 	return credClass;
	// };

	// const creditCardTypeEnum = Object.values(CreditCardType);
	// const CardDropDownDownField = () => {
	// 	if (!MenuXDebtorListLoading && getMenuXDebtorList?.data?.MenuXDebtorList) {
	// 		return (
	// 			<TextField select>
	// 				{getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
	// 					x => x?.GuestStatus === 'CARD',
	// 				)?.map((el: any, index: number) => (
	// 					<MenuItem key={index} value={el}>
	// 						{creditCardText(el?.GuestType)}
	// 					</MenuItem>
	// 				))}
	// 			</TextField>
	// 		);
	// 	} else {
	// 		return (
	// 			<TextField select>
	// 				{creditCardTypeEnum?.map((el: any, index: number) => (
	// 					<MenuItem key={index} value={el}>
	// 						{creditCardText(el)}
	// 					</MenuItem>
	// 				))}
	// 			</TextField>
	// 		);
	// 	}
	// };

	useEffect(() => {
		if (
			getMenuXInhouseList?.data?.MenuXInhouseList &&
			getMenuXInhouseList?.data?.MenuXInhouseList.length > 0
		) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList]);

	useEffect(() => {
		if (
			getMenuXDebtorList?.data?.MenuXDebtorList &&
			getMenuXDebtorList?.data?.MenuXDebtorList.length > 0
		) {
			setOriginalListing(getMenuXDebtorList?.data?.MenuXDebtorList);
		}
	}, [getMenuXDebtorList]);

	const disableButton = () => {
		let p = selectedPaymentClass();

		if (p === PaymentClass.Room) {
			return (
				Object?.values(selectedRoom ?? {})?.length <= 0 &&
				Object?.values(defaultRoom ?? {})?.length <= 0
			);
		} else if (p === PaymentClass.Debtor) {
			return (
				Object?.values(selectedDebtor ?? {})?.length <= 0 &&
				Object?.values(defaultDebtor ?? {})?.length <= 0
			);
		} else {
			return (
				watch('defaultPaymentID') === undefined ||
				watch('defaultPaymentID') === null
			);
		}
	};

	return (
		<>
			{(MenuXInhouseListLoading || MenuXDebtorListLoading) && <Loading />}

			<CommonDialogV2
				fullWidth
				disableEnforceFocus
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
					dispatch({
						type: 'reset',
						payload: false,
					});
					setRadioError(false);
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													Bill Settlement
												</div>
												<div className="highlight-text title">{`$${amtStr(
													total,
												)}`}</div>
											</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
										</div>
									</div>
									{/* <div className="session">
										<div className="flex session">
											<div
												className="xsTitle flex-space infoline-content"
												style={{ color: 'black' }}
											>
												{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
											</div>
										</div>
									</div> */}
								</div>
							</>
						),
					},
					body: () => (
						<>
							<span className="mdDesc" style={{ paddingLeft: '15px' }}>
								Payment Method
							</span>
							<RadioGroup
								aria-label="paymentMethod"
								name="paymentMethodRadio"
								onChange={e => {
									register('defaultPaymentID');
									setValue(`defaultPaymentID`, e.target.value);
								}}
							>
								{customerQuery(getDefaultPayment)
									?.filter(s => s?.isActive === true)
									?.map((v, i) => (
										<>
											<FormControlLabel
												value={v?.ID}
												control={
													<Radio color="primary" name="defaultPaymentID" />
												}
												onClick={() => {
													handleRadio(v);
													if (
														(v?.paymentClass === PaymentClass.Credit ||
															v?.paymentClass === PaymentClass.Debtor) &&
														hotelID
													) {
														loadMenuXDebtorList({
															variables: {
																hotelID: hotelID,
																outletID: outletID,
															},
														});
													}
													if (
														v?.paymentClass === PaymentClass.Room &&
														hotelID
													) {
														loadMenuXInhouseList({
															variables: {
																hotelID: hotelID,
																outletID: outletID,
															},
														});
													}
												}}
												style={{ paddingLeft: '15px' }}
												className={
													v?.paymentClass === PaymentClass.Cash
														? 'cash-radio'
														: null
												}
												label={
													<>
														<Grid container>
															<Grid
																item
																xs={
																	v?.paymentClass !== PaymentClass.Cash ? 12 : 8
																}
																style={{
																	alignSelf: 'center',
																	paddingBottom: '0px',
																	paddingTop: '4px',
																}}
															>
																<span>{v?.paymentTypeInfo?.description}</span>
																{v?.paymentClass === PaymentClass.Room &&
																	defaultRoom?.CreditBalance < total && (
																		<div
																			className="desc"
																			style={{ color: '#e53935' }}
																		>
																			Not enought Credit Balance!
																		</div>
																	)}
															</Grid>
														</Grid>
													</>
												}
											/>
										</>
									))}
							</RadioGroup>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setSelectedRoom({});
										setSelectedDebtor({});
										setSelectedCredit({});
										setOpenDialog(false);
										dispatch({
											type: 'reset',
											payload: false,
										});
										setRadioError(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: paymentType === 'settlement' ? 'Confirm' : 'Next',
								props: {
									onClick: () => {
										if (selectedPaymentClass() === PaymentClass.Credit) {
											if (
												watch('cardType') === undefined ||
												watch('cardReference') === undefined
											) {
												setRadioError(true);
											} else if (
												paymentType == 'voidPayment' ||
												paymentType == 'voidBill'
											) {
												setOpenReason(true);
												setOpenDialog(false);
											} else {
												handleSubmit(onSubmit)();
											}
										} else if (
											selectedPaymentClass() === PaymentClass.Room ||
											selectedPaymentClass() === PaymentClass.Debtor
										) {
											if (
												defaultRoom?.CreditBalance >= total ||
												selectedRoom?.CreditBalance >= total ||
												defaultDebtor?.CreditBalance >= total ||
												selectedDebtor?.CreditBalance >= total
											)
												handleSubmit(() => {
													setOpenSignature(true);
												})();
										} else if (selectedPaymentClass() === PaymentClass.Member) {
											handleSubmit(() => {
												setOpenSignature(true);
											})();
										} else if (
											paymentType == 'voidPayment' ||
											paymentType == 'voidBill'
										) {
											setOpenReason(true);
											setOpenDialog(false);
										} else {
											handleSubmit(onSubmit)();
										}
									},
									variant: 'contained',
									color: disableButton() ? 'primary' : 'inherit',
									disabled: disableButton(),
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SignatureDialog = (props: SignatureProps) => {
	const {
		handleSubmit,
		onSubmit,
		openSignature,
		selectedOrder,
		setOpenDialog,
		setOpenSignature,
		total,
	} = props;

	const {
		files,
		handleUploadChange,
		previewFiles,
		remove: removeFile,
		handleEditUpload,
		setFiles,
	} = useUploadAttachment();

	const [signatureImage, setSignatureImage] = useState(null);

	let sigCanvas = {};

	const clear = () => {
		const signature: any = sigCanvas;
		signature.clear();
	};

	const saveSigCanvas = () => {
		const signature: any = sigCanvas;
		signature.getTrimmedCanvas().toBlob(blob => {
			setSignatureImage(blob);
		});
	};

	useEffect(() => {
		if (signatureImage) {
			signatureImage['name'] = 'sigName';
			const newFiles = [signatureImage];
			setFiles([...newFiles]);
			handleSubmit(data => onSubmit(data, newFiles))();
		}
	}, [signatureImage]);

	return (
		<>
			<CommonDialogV2
				fullWidth
				open={openSignature}
				onClose={() => setOpenSignature(false)}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													Signature
												</div>
												<div className="highlight-text title">{`$${amtStr(
													total,
												)}`}</div>
											</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{selectedOrder
												? `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`
												: null}
										</div>
									</div>
								</div>
							</>
						),
						// title: 'Signature',
						// subtitle: selectedOrder
						// 	? `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`
						// 	: null,
						// totalAmount: selectedOrder ? `$${amtStr(total)}` : null,
					},
					body: () => (
						<>
							<Grid container style={{ paddingTop: '10px' }}>
								<Grid item xs={8}>
									<span className="sigTitleText"> Sign Here </span>
								</Grid>
								<Grid item xs={4} style={{ textAlign: 'right' }}>
									<span onClick={clear} className="highlight-text">
										{' '}
										Reset
									</span>
								</Grid>
							</Grid>

							<SignatureCanvas
								ref={ref => {
									sigCanvas = ref;
								}}
								penColor="black"
								canvasProps={{
									className: 'sigCanvas',
								}}
							/>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenSignature(false);
									},

									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										saveSigCanvas();
										// handleSubmit(onSubmit)();
										setOpenSignature(false);
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
									//disabled: isExceed,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const ReasonDialog = (props: ReasonProps) => {
	const {
		openReason,
		setOpenReason,
		isBill,
		setIsBill,
		getReason,
		control,
		register,
		watch,
		paymentID,
		handleSubmit,
		onSubmit,
		setSignatureDialog,
	} = props;
	return (
		<>
			<CommonDialogV2
				fullWidth={true}
				open={openReason}
				onClose={() => {
					setOpenReason(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{isBill ? 'Void Bill' : 'Void Payment'}
										</div>
									</div>
								</div>
							</div>
						),
					},

					body: () => (
						<>
							<Controller
								as={
									<TextField select size="small" label="Reason Code">
										{getReason?.map((x, index) => (
											<MenuItem key={index} value={x?.name}>
												{x?.name}
											</MenuItem>
										))}
									</TextField>
								}
								name="reasonCode"
								autoComplete="off"
								control={control}
								multiline
								fullWidth
								margin="normal"
							></Controller>
							<Controller
								as={<TextField />}
								name="remark"
								label="Remark"
								autoComplete="off"
								control={control}
								multiline
								ref={register}
								fullWidth
								margin="normal"
							></Controller>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										if (isBill) {
											setOpenReason(false);
											setIsBill(false);
										} else {
											setOpenReason(false);
										}
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: paymentID(watch('defaultPaymentID'))
									? 'Next'
									: 'Confirm',
								props: {
									onClick: () => {
										if (openReason) {
											if (paymentID(watch('defaultPaymentID'))) {
												handleSubmit(() => {
													setSignatureDialog(true);
													setOpenReason(false);
												})();
											} else {
												handleSubmit(onSubmit)();
											}
										} else {
											setSignatureDialog(true);
											setOpenReason(false);
										}
									},
									variant: 'contained',
									color:
										watch('reasonCode') !== undefined &&
										watch('reasonCode') !== null &&
										watch('remark') !== undefined &&
										watch('remark') !== null
											? 'primary'
											: 'inherit',
									disabled:
										watch('reasonCode') === undefined &&
										watch('reasonCode') !== null &&
										watch('remark') !== undefined &&
										watch('remark') !== null,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
