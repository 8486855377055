import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { FooterWithTotal } from '@ifca-root/react-component/src/components/Footer/FooterWithTotal';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import { NoImage } from 'assets';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import BigNumber from 'bignumber.js';
import { MainHeaderCustomer } from 'components/Layout/MainHeaderCustomer';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	OrderItemStatus,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletQuery,
	useGetAllOrderItemLazyQuery,
	TableStatus,
	OrderStatus,
} from 'generated/graphql';
import { formatTimeto12h } from 'helpers/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import { WSclient } from '../../../POSClient';
import './order.scss';

export const ConfirmOrder = () => {
	useEffect(() => {
		console.log('ConfirmOrder Customer App');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();

	const [quantity, setQuantity] = useState(0); // this should be changed accordingly
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [deleteDialog, setDeleteDialog] = useState(false);
	const [forDelete, setForDelete] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const { outletID, tableID, qrID, menuID, orderID } = useParams<
		Record<string, any>
	>();

	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const [negOrderItem, setNegOrderItem] = useState({});
	const [itemArray, setItemArray] = useState([]);
	const [
		loadOrderItem,
		{
			called: getAllOrderItemCalled,
			data: { getAllOrderItem } = {
				getAllOrderItem: [],
			},
			fetchMore,
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {
			let allOrderItem = getAllOrderItem?.filter(item => item?.billID === null);

			let temp = {};
			allOrderItem?.map(el => {
				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID] += el?.quantity * -1;
					} else {
						temp[el?.refID] = el?.quantity * -1;
					}
				}
			});
			setNegOrderItem({ ...temp });
			setItemArray(
				allOrderItem
					// ?.map(el => {
					// 	if (el?.quantity - temp[el?.ID] === 0) {
					// 		return { ...el, void: true };
					// 	} else {
					// 		return { ...el, void: false };
					// 	}
					// })
					// ?.filter(list => list.void === false)
					?.filter(list2 => list2.quantity > 0),
			);
		},
	});

	useEffect(() => {
		if (!getAllOrderItemCalled) {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
		}
	}, [getAllOrderItemCalled]);

	//subscription //subscription //subscription

	// const {
	// 	loading: newOrderItemLoading,
	// 	data: { newOrderItem } = { newOrderItem: null },
	// } = useNewOrderItemSubscription({
	// 	variables: {
	// 		outletID: outletID,
	// 	},
	// 	client: WSclient,
	// });

	// useEffect(() => {
	// 	if (newOrderItem?.ID) {
	// 		fetchMore({
	// 			query: getAllOrderItemDocument,
	// 			variables: {
	// 				orderID: orderID,
	// 			},
	// 			updateQuery: (prev: any, { fetchMoreResult }) => {
	// 				return [...prev.getAllOrderItem, fetchMoreResult];
	// 			},
	// 		});
	// 	}
	// }, [newOrderItem?.ID]);

	//subscription //subscription //subscription

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});
	const selfOrder = getOutlet[0]?.customerPolicy?.selfOrder;
	const selfPayment = getOutlet[0]?.customerPolicy?.selfPayment;

	const {
		data: { getOrder } = { getOrder: [] },
		called: getOrderCalled,
		loading: getOrderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: { ID: orderID, status: OrderStatus.Open, outletID },
	});

	const status = getOrder[0]?.table?.status;

	useEffect(() => {
		if (status === TableStatus?.Available) {
			history.push(`/menu/patron-app/invalid-qr`);
		}
	}, [status]);

	const {
		data: { getOrderItem } = { getOrderItem: [] },
		loading: getOrderSummaryLoading,
	} = useGetOrderSummaryQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			status: OrderItemStatus.Pending,
		},
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [selectedOrder, setSelectOrder] = useState(null);

	useEffect(() => {
		setSelectOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	let totalAmountFooter = amtStr(selectedOrder?.outstandingAmount);

	return (
		<>
			{outletLoading && <Loading />}
			{getOrderLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{getOrderSummaryLoading && <Loading />}

			<MainHeaderCustomer
				onClick={() =>
					history.push(
						getOrderItem?.length > 0
							? `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary`
							: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}`,
					)
				}
				mainBtn="back"
				smTitle="Customer App"
				title={getOutlet[0]?.name}
				currency={
					<span className="xxTitle flex-space" style={{ color: 'white' }}>
						Table
						<span className="title flex-space" style={{ color: 'orange' }}>
							{' ' + selectedOrder?.table?.tableNumber}
						</span>
					</span>
				}
				routeSegments={[
					{ name: 'Customer App' },
					{ name: 'Confirm Order', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTimeto12h(
									currentMealPeriod?.startTime,
							  )} - ${formatTimeto12h(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<OrderSubheader
				getOrderLoading={getOrderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				outletID={outletID}
			/>
			<ContentWrapper customerDynamicInfo>
				{!outletLoading &&
					!getOrderLoading &&
					!getCurrentMealPeriodLoading &&
					(getAllOrderItem === undefined || itemArray?.length === 0 ? (
						<EmptyList
							title="There are no orders found"
							subtitle="Please make a new order in the Menu "
						/>
					) : (
						<List className="core-list">
							{itemArray
								// ?.filter(list1 => list1.void === false)
								?.filter(list2 => list2.refID === null)
								?.map((oi, index) => (
									<ListItem style={{ padding: '0px 10px 7px' }}>
										{/* <div style={{ paddingRight: '6px' }}>
										<img
											src={oi?.menuItem?.attachments?.fileURL}
											className="img-owned"
											loading="lazy"
											// alt={}
										/>
									</div> */}
										<ListItemIcon>
											{oi?.menuItem?.attachments?.fileURL ? (
												<img
													className="img-owned"
													src={oi?.menuItem?.attachments?.fileURL}
												/>
											) : (
												<img
													className="img-owned"
													style={{ border: 'none' }}
													src={NoImage}
												/>
											)}
											{oi?.isTakeaway && (
												<img className="img-takeaway-logo" src={PickupIcon} />
											)}
										</ListItemIcon>
										<ListItemText
											primary={
												<>
													<Grid container className="smTitle">
														{/* <Grid item xs={1} style={{ textAlign: 'center' }}>
													{`${index + 1}. `}
												</Grid> */}
														<Grid item xs={6} className="flex-space">
															{`${oi?.openItemName ??
																oi?.menuItem?.name} x${Math.abs(
																oi?.quantity - negOrderItem[oi?.ID] ||
																	oi?.quantity,
															)}`}
														</Grid>
														<Grid
															item
															xs={6}
															style={{
																textAlign: 'right',
																paddingRight: '10px',
															}}
															className="highlight-text"
														>
															{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
																new BigNumber(oi?.totalInfo?.totalAmount)
																	.times(
																		new BigNumber(oi?.quantity)
																			.minus(negOrderItem[oi?.ID] ?? 0)
																			.toNumber(),
																	)
																	.div(oi?.quantity)
																	.toNumber(),
															)}`}
															{/* {`$${amtStr(
																oi?.totalInfo?.totalAmount *
																	((oi?.quantity - negOrderItem[oi?.ID]) /
																		oi?.quantity) ||
																	oi?.totalInfo?.totalAmount,
															)}`} */}
														</Grid>
														{/* <Grid item xs={1} style={{ textAlign: 'center' }}>
													<DeleteIcon
														color={'action'}
														fontSize="small"
														onClick={() => {
															setDeleteDialog(true);
															setForDelete(el);
														}}
													/>
												</Grid> */}
													</Grid>
												</>
											}
											secondary={
												oi?.orderItemOption?.length
													? oi?.orderItemOption?.map(oiopt => (
															<>
																{oiopt?.orderItemOptionItem?.map(oioptitem => (
																	<Grid container className="mdDesc">
																		{/* <Grid item xs={1}></Grid> */}
																		<Grid item xs={6} className="flex-space">
																			{oioptitem?.optionItem?.name}
																		</Grid>
																		{/* <Grid
																	item
																	xs={6}
																	style={{
																		textAlign: 'right',
																		paddingRight: '10px',
																	}}
																>
																	{amtStr(e?.price)}
																</Grid> */}
																		{/* <Grid item xs={1}></Grid> */}
																	</Grid>
																))}
															</>
													  ))
													: oi?.orderItemSetMenuOption?.map(oisetopt => (
															<>
																{oisetopt?.orderItemSetMenuOptionItem?.map(
																	oisetoptitem => (
																		<Grid container className="mdDesc">
																			{/* <Grid item xs={1}></Grid> */}
																			<Grid item xs={6} className="flex-space">
																				{
																					oisetoptitem?.setMenuOptionItem
																						?.menuItem?.name
																				}
																			</Grid>
																			{/* <Grid
																		item
																		xs={4}
																		style={{
																			textAlign: 'right',
																			paddingRight: '10px',
																		}}
																	>
																		{amtStr(e?.price)}
																	</Grid>
																	<Grid item xs={1}></Grid> */}
																		</Grid>
																	),
																)}
															</>
													  ))
											}
										/>
									</ListItem>
								))}
						</List>
					))}
			</ContentWrapper>
			<div style={{ marginBottom: '100px' }}></div>

			{!selfOrder || (!selfPayment && selfOrder) ? null : (
				<FloatButton
					// disabled={value !== 0 ? true : false}
					footer
					onClick={() =>
						history.push(
							`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}`,
						)
					}
				/>
			)}
			{/* <Footer
				options={[
					{
						name: 'Payment',
						onClick: () => {
							if (getAllOrderItem?.length !== 0) {
								history.push(
									`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order/payment`,
								);
							}
						},
						disabled: getAllOrderItem?.length === 0,
						color: getAllOrderItem?.length !== 0 ? 'primary' : 'inherit',
						props: {
							type: 'submit',
						},
					},
				]}
			/> */}

			<FooterWithTotal
				label="Total"
				value={`${getOutlet[0]?.currencyCode ?? ''}${totalAmountFooter || 0}`}
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				option={
					selfPayment
						? {
								name: 'Payment',
								onClick: () => {
									if (getAllOrderItem?.length !== 0) {
										history.push(
											`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order/payment`,
										);
									}
								},
								color: 'primary',
						  }
						: !selfPayment && selfOrder
						? {
								name: 'View Menu',
								onClick: () => {
									history.push(
										`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}`,
									);
								},
								color: 'primary',
						  }
						: null
				}
			/>
		</>
	);
};
